import React from "react"
import { Row, Col, Card } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import Profile from "../components/profile"
import "bootstrap/dist/css/bootstrap.min.css"
import styles from "../styles/article_list.module.css"

class CategoryTemplate extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges
    const siteTitle = data.site.siteMetadata.title
    const profileImage = data.site.siteMetadata.profile

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Row>
          {posts.map(({ node }) => {
            return (
              <Col
                lg={{ span: 8, offset: 2 }}
                xl={{ span: 6, offset: 3 }}
                key={node.id}
              >
                <Link className={styles.list} to={node.fields.slug}>
                  <Card className={styles.item}>
                    <Card.Img
                      variant="top"
                      className={styles.image}
                      src={node.frontmatter.image.publicURL}
                    />
                    <Card.Body>
                      <Card.Title className={styles.title}>
                        {node.frontmatter.title}
                      </Card.Title>
                      <Card.Text>
                        <time className={styles.time}>
                          {node.frontmatter.date}
                        </time>
                        <div>
                          <span className={styles.tag}>
                            {node.frontmatter.category}
                          </span>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            )
          })}
        </Row>
        <Profile profileImage={profileImage} />
      </Layout>
    )
  }
}

export default CategoryTemplate

export const pageQuery = graphql`
  query BlogPostByCategory($category: String!) {
    site {
      siteMetadata {
        title
        profile
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category
            description
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`

CategoryTemplate.propTypes = {
  data: PropTypes.object,
  posts: PropTypes.object,
  siteTitle: PropTypes.string,
  profileImage: PropTypes.string,
  location: PropTypes.object,
}
