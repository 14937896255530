import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import {
  faGithub,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "bootstrap/dist/css/bootstrap.min.css"
import styles from "../styles/profile.module.css"

class Profile extends React.Component {
  render() {
    const { profileImage } = this.props

    return (
      <Col className={styles.profile} md={12}>
        <div className={styles.top}>
          <img src={profileImage} className={styles.image} />
          <a className={styles.author} href="/profile-ky/">
            Katsuya Yamaguchi
          </a>
        </div>

        <div className={styles.context}>
          <p>
            主にインフラエンジニアをしております。時にはWebサービスを作り、動画を作成したりもします。
          </p>
          <div className={styles.sns}>
            <a
              className={styles.anckor}
              href="https://github.com/katsuya-yamaguchi"
            >
              <FontAwesomeIcon
                icon={faGithub}
                className={styles.icon}
                size="2x"
              />
            </a>
            <a
              className={styles.anckor}
              href="https://twitter.com/katsuya71109608"
            >
              <FontAwesomeIcon
                icon={faTwitter}
                className={styles.icon}
                size="2x"
              />
            </a>
            <a
              className={styles.anckor}
              href="https://www.instagram.com/katsuyayamaguti/"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className={styles.icon}
                size="2x"
              />
            </a>
          </div>
        </div>
      </Col>
    )
  }
}

export default Profile

Profile.propTypes = {
  profileImage: PropTypes.string,
}
